<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createSupplierHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add Suppliers</vs-button>
      </div>
    </div>
    <SuppliersList
      :supplierData="supplierData"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getSuppliersList"
      @viewSupplierDetail="viewSupplierDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SuppliersList from "../../../components/WebStore/suppliers/Suppliers";

export default {
  components: {
    SuppliersList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      popUpDelete:false,
      supplierData: [],
      noDataText: 'Loading...'
    };
  },
  methods: {
    ...mapActions("supplier", [
      "getSupplierList"
    ]),
    async getSuppliersList(dataTableParams) {
      let self = this;
      this.getSupplierList(dataTableParams).then( async (res) => {
        self.supplierData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = 'No Suppliers Available'
      });
    },
    createSupplierHandler(){
      this.$router.push({ name: 'adminStoreAddSuppliers'})
    },
   viewSupplierDetail(id) {
      this.$router.push({
        name: "adminStoreViewSuppliers",
        params: { supplierId: id }
      });
    }
  }
};
</script>
